import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"transition":"dialog-top-transition","persistent":"","width":"500","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.showDialogNotes),callback:function ($$v) {_vm.showDialogNotes=$$v},expression:"showDialogNotes"}},[_c(VCard,[_c(VCardTitle,{staticClass:"lighten-2 formDialog",staticStyle:{"border-bottom":"solid 1px var(--color__cinza)","font-size":"17px"}},[_vm._v(" Communication for the customer ")]),_c(VCardText,{staticStyle:{"margin-top":"20px"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c(VMenu,{ref:"mnuExpirationDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',[_vm._v("Expiration Date")]),_c(VTextField,_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","rules":[_vm.validations.required],"readonly":"","outlined":"","single-line":"","dense":""},on:{"blur":function($event){_vm.expirationDate = _vm.parseDate(_vm.expirationDateFormatted)}},model:{value:(_vm.expirationDateFormatted),callback:function ($$v) {_vm.expirationDateFormatted=$$v},expression:"expirationDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.mnuExpirationDate),callback:function ($$v) {_vm.mnuExpirationDate=$$v},expression:"mnuExpirationDate"}},[_c(VDatePicker,{attrs:{"no-title":"","min":_vm.today,"color":"primary lighten-1"},on:{"input":function($event){_vm.mnuExpirationDate = false}},model:{value:(_vm.expirationDate),callback:function ($$v) {_vm.expirationDate=$$v},expression:"expirationDate"}})],1)],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',[_vm._v("Classification")]),_c(VCombobox,{attrs:{"items":_vm.listClassificationNotes,"item-text":"classificationDescription","item-value":"id","clearable":"","outlined":"","dense":""},model:{value:(_vm.classificationNotesSelected),callback:function ($$v) {_vm.classificationNotesSelected=$$v},expression:"classificationNotesSelected"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[_c('label',[_vm._v("Message")]),_c(VTextarea,{attrs:{"rows":"4","row-height":"23","auto-grow":"","outlined":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1),_c(VDivider),_c(VCardActions,{staticStyle:{"padding":"15px !important"}},[_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c(VBtn,{staticClass:"mx-2 dialogButtonCancel v-btn-large",attrs:{"outlined":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"mx-2 v-btn-large",attrs:{"outlined":""},on:{"click":_vm.confirm}},[_vm._v(" Confirm ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }