<template>
    <div>
        <v-dialog
            v-model="showDialogPayments"
            transition="dialog-top-transition"
            persistent
            width="700"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
            <v-card>
                <v-card-title class="lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza); font-size: 17px;">
                    Payments
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Phase</label>
                            <v-combobox
                                v-model="phaseSelected"
                                :items="listPhase"
                                item-text="description"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                outlined
                                dense
                                @change="adjustValue"
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Type of Payment</label>
                            <v-combobox
                                v-model="typePaymentSelected"
                                :items="listTypePayments"
                                item-text="typePaymentsDescription"
                                item-value="id"
                                :rules=[validations.required]
                                clearable
                                outlined
                                dense
                            ></v-combobox>
                        </v-col>
                    </v-row>
                    <v-row v-if="showCheckOrWireNumber">
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>{{ textCheckOrWireTransfer }} </label>
                            <v-text-field
                                v-model="checkNumber" 
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-menu
                                ref="mnuPaymentDate"
                                v-model="mnuPaymentDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <label>Payment Date</label>
                                    <v-text-field
                                        v-model="paymentDateFormatted"
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        :rules=[validations.required]
                                        @blur="paymentDate = parseDate(paymentDateFormatted)"
                                        readonly
                                        outlined
                                        single-line
                                        v-on="on"
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="paymentDate"
                                    no-title
                                    @input="mnuPaymentDate = false"
                                    :max="today"
                                    color="primary lighten-1"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="phaseSelected != null && phaseSelected != undefined"
                    >
                        <v-col
                            cols="12"
                            lg="2"
                            md="2"
                            sm="12"
                        >
                            <label>Installment</label>
                            <v-text-field
                                single-line
                                outlined
                                dense
                                disabled
                                :value="phaseSelected.parcelNumber"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="2"
                            md="2"
                            sm="12"
                        >
                            <label>Percentage</label>
                            <v-text-field
                                single-line
                                outlined
                                dense
                                disabled
                                suffix=" %"
                                :value="phaseSelected.percentage"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="8"
                            md="8"
                            sm="12"
                        >
                            <label>Value</label>
                            <v-text-field
                                v-model="value" 
                                v-formatMoney="moneyFormat"
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                                :disabled="userLoggedGetters.profile.systemAdmin != 1"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Notes</label>
                            <v-textarea
                                v-model="notes"
                                single-line
                                rows="3"
                                row-height="20"
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>

				<v-divider></v-divider>

				<v-card-actions style="padding: 15px !important;">
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel v-btn-large"
							outlined
							@click="cancel"
						>
							Cancel
						</v-btn>

						<v-btn
							class="mx-2 v-btn-large"
							outlined
                            @click="confirm"
						>
							Confirm
						</v-btn>
					</div>
				</v-card-actions>

            </v-card>
        </v-dialog> 
	</div>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import { required } from "@/utilities/Rules";
    import { formaterDecimalBR, formaterDecimalBRServer } from '@/utilities/Utils';

	export default {

        mixins: [Helpers],

        props: {
			
            id: {
                type: Number,
                default: 0
            },
			
            idServicePayment: {
                type: Number,
                default: 0
            },
			
            totalJob: {
                type: Number,
                default: 0
            },

			showDialogPayments: {
				default: false
			},
		},
		
        data: vm => ({

            listTypePayments: [],
			
            note: "",

            today: (new Date(Date.now() - ( new Date() ).getTimezoneOffset() * 60000) ).toISOString().substr(0, 10),
            paymentDate: vm.today,
            paymentDateFormatted: vm.formatDate(vm.today),
            mnuPaymentDate: false,
            
            phaseSelected: null,
            typePaymentSelected: null,
            checkNumber: '',
            value: "$ 0,00",
            notes: "",

            listPhase: [],

            moneyFormat: {
                prefix: '$ ',
                suffix: '',
                thousands: ',',
                decimal: '.',
                precision: 2,
            },

            og: Math.pow(10, 2),
            
            validations: {
                required: required
            }
		}),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            showCheckOrWireNumber() {
                return this.typePaymentSelected != null && 
                       this.typePaymentSelected != undefined && 
                      (this.typePaymentSelected.id == 1 || 
                       this.typePaymentSelected.id == 3)
            },

            textCheckOrWireTransfer() {
                if (this.isWireTransfer) {
                    return 'Tracking Number'
                }
                else if (this.isCheck) {
                    return 'Check Number'
                }
                else {
                    return ''
                }
            },

            isWireTransfer() {
                return this.showCheckOrWireNumber && this.typePaymentSelected.id == 1
            },

            isCheck() {
                return this.showCheckOrWireNumber && this.typePaymentSelected.id == 3
            }
        },

        watch: {

            async "id"() {
                this.clearFields();
				this.getRegisters();
            },

            paymentDate () {
                this.paymentDateFormatted = this.formatDate(this.paymentDate)
            },
        },

        methods: {

            clearFields() {
                this.phaseSelected = null;
                this.typePaymentSelected = null;
                this.checkNumber = '';
                this.value = "$ 0,00";
            },

            async getRegisters() {
                this.listPhase = await this.$store.dispatch("servicePaymentsConfigurationModule/ListPaymentsConfigurationByJob", this.id);
                this.listTypePayments = await this.$store.dispatch("servicePaymentsModule/ListTypePayments");
            },
            
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },

            adjustValue() {
                if (this.phaseSelected != null && this.phaseSelected != undefined) {
                    const value = Math.floor((this.totalJob * (this.phaseSelected.percentage / 100)) * this.og) / this.og
                    this.value = `$ ${formaterDecimalBR(value)}`;
                }
            },

            cancel() {
                this.$emit('update:showDialogPayments', false);
            },

            async confirm() {

                if (this.phaseSelected != null && 
                    this.phaseSelected != undefined &&
                    this.typePaymentSelected != null && 
                    this.typePaymentSelected != undefined) {

                    const paymentDate = this.paymentDate;
                    const value = formaterDecimalBRServer(this.value);
                    const notes = this.notes;
                    const paymentType = this.typePaymentSelected.id;

                    const servicePaymentRequest = {
                        id: this.idServicePayment,
                        idServicePaymentsConfiguration: this.phaseSelected.id,
                        type: paymentType,
                        checkNumber: this.checkNumber,
                        date: paymentDate,
                        value,
                        notes,
                    };
                    
                    const result = await this.$store.dispatch("servicePaymentsModule/CreateUpdatePayments", servicePaymentRequest);

                    if (result.success) {
                        this.showToast("success", "Success!", "Payment registered successfully!");
                    
                        this.$emit('methodConfirmToCall');
                        this.cancel();
                    }
                }
                else {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                }
            }
        },

        async created() {
            this.getRegisters()
        }
    };
</script>