<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="7"
                lg="10"
                md="10"
                sm="6"
            >
                <h3>Payments</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        label="Quick Search"
                        prepend-inner-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click:prepend-inner="getRegisters"
                        dense
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col 
                cols="12"
                lg="6"
                md="6"
                sm="12"
            >
                <label>Customer</label>
                <v-combobox
                    v-model="filter.customerSelected"
                    :items="listCustomer" 
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="4"
                md="6"
                sm="12"
            >
                <label>Status</label>
                <v-combobox
                    v-model="filter.statusSelected"
                    :items="listStatus" 
                    item-text="statusDescription"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    @change="filterRegisters()"
                ></v-combobox>
            </v-col>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="12"
                lg="2"
                md="6"
                sm="12"
            >
                <v-switch 
                    v-model="filter.showFullyPayed"
                    label="Show fully payed jobs"
                    :value=1
                    color="var(--color__main)"
                    @change="filterRegisters()"
                >
                </v-switch>            
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :single-expand="true"
                    show-expand
                    no-data-text="No Record Found"
                    @click:row="showPaymentDetails"
                >
                    <template v-slot:item.id="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <a v-bind:href="item.customerUrl" v-on="on" target="_blank">
                                    {{ item.id }}
                                </a>
                            </template>
                            <span>Tracker Page</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status, item.situation)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }} {{ item.situation == 2 || item.situation == 3 ? '[H]' : '' }}
                        </v-chip>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <h3 style="margin-top: 10px;">Payment Details</h3>
                            <table cellspacing="10" style="width: 90%; border: none; margin: 15px;">
                                <thead style="text-align: left;">
                                    <tr>
                                        <th>Created On</th>
                                        <th>Created By</th>
                                        <th>Type</th>
                                        <th>Number</th>
                                        <th>Paid On</th>
                                        <th>Description</th>
                                        <th>Percentage</th>
                                        <th>Installment</th>
                                        <th>Value</th>
                                        <th>Notes</th>
                                    </tr>
                                </thead>
                                <tbody style="padding: 10px;">
                                    <tr v-for="(itemPayment, index) in item.listPaymentDetails" :key="index">
                                        <td>{{ itemPayment.dateRegisterFormatted }}</td>
                                        <td>{{ itemPayment.userNameRegister }}</td>
                                        <td>{{ itemPayment.typeDescription }}</td>
                                        <td>{{ itemPayment.checkNumber }}</td>
                                        <td>{{ itemPayment.dateFormatted }}</td>
                                        <td>{{ itemPayment.description }}</td>
                                        <td>{{ itemPayment.percentage }} %</td>
                                        <td>{{ itemPayment.parcelNumber }}</td>
                                        <td>{{ itemPayment.valueFormatted }}</td>
                                        <td>{{ itemPayment.notes }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </template>

                    <template v-slot:item.balanceValueFormatted="{ item }">
                        <span
                           :style="getValueColor(item.totalJob, item.totalPaid)"
                        >
                            {{ item.balanceValueFormatted }}
                        </span>
                    </template>
                    
                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'serviceForm'"
                            :showButtons="{
                                hold: item.situation == 1 ? true : false,
                                resume: item.situation === 2 ? true : false,
                                notes: true,
                                payments: true
                            }"
                            @confirmHold="showDialogHold('H', item.id)"
                            @confirmResume="showDialogHold('R', item.id)"
                            @confirmNotes="showDialogNotes"
                            @confirmPayments="showDialogPayments(item.id, item.idServicePayment, item.totalJob)"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <DialogHold
            :id="dialogHoldResume.id"
            :type="dialogHoldResume.type"
            :projectOrService="dialogHoldResume.projectOrService"
            v-on:methodConfirmToCall="dialogHoldResume.methodConfirm"
            :showDialogHold.sync="dialogHoldResume.show"
        />

        <DialogNotes
            :id="dialogNotes.id"
            v-on:methodConfirmToCall="dialogNotes.methodConfirm"
            :showDialogNotes.sync="dialogNotes.show"
        />

        <DialogPayments
            :id="dialogPayments.id"
            :idServicePayment="dialogPayments.idServicePayment"
            :totalJob="dialogPayments.totalJob"
            v-on:methodConfirmToCall="dialogPayments.methodConfirm"
            :showDialogPayments.sync="dialogPayments.show"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import DialogHold from "@/components/Services/DialogHold";
    import DialogNotes from "@/components/Services/DialogNotes";
    import DialogPayments from "@/components/Services/DialogPayments";
    import { orderBy } from '@/utilities/Utils';
    export default ({

        components: {
            ActionList,
            ActionDialog,
            DialogHold,
            DialogNotes,
            DialogPayments
        },

        mixins: [Helpers],

        data: () => ({

            loading: false,

            filter: {
                fastSearch: '',
                customerSelected: null,
                statusSelected: null,
                showFullyPayed: 0
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Customer", value: "customerName", sortable: true },
                { text: "Total Job", value: "totalJobFormatted", sortable: false, align: "right" },
                { text: "Total Paid", value: "totalPaidFormatted", sortable: false, align: "right" },
                { text: "Balance", value: "balanceValueFormatted", sortable: false, align: "right" },
                { text: "Actions", value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listPayments: [],
            listPaymentsFiltered: [],

            listCustomer: [],
            listStatus: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            dialogHoldResume: {
                id: 0,
                type: "H",
                projectOrService: 'S',
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogNotes: {
                id: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogPayments: {
                id: 0,
                idServicePayment: 0,
                totalJob: 0,
                show: false,
                methodConfirm: ( () => {} ),
            },

            dialogPaymentsDetails: {
                idAux: 0,
                idServicePayment: 0,
                show: false
            },
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),

            filteredSearch: function() {
                return this.listPaymentsFiltered.filter((paymentFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var customerName = paymentFilter.customerName != null && paymentFilter.customerName != undefined ? paymentFilter.customerName.toLowerCase().match(filter): ""
                    var payment1ValueFormatted = paymentFilter.payment1ValueFormatted != null && paymentFilter.payment1ValueFormatted != undefined ? paymentFilter.payment1ValueFormatted.toLowerCase().match(filter): ""
                    var payment2ValueFormatted = paymentFilter.payment2ValueFormatted != null && paymentFilter.payment2ValueFormatted != undefined ? paymentFilter.payment2ValueFormatted.toLowerCase().match(filter) : ""
                    var payment3ValueFormatted = paymentFilter.payment3ValueFormatted != null && paymentFilter.payment3ValueFormatted != undefined ? paymentFilter.payment3ValueFormatted.toLowerCase().match(filter) : ""
                    var payment4ValueFormatted = paymentFilter.payment4ValueFormatted != null && paymentFilter.payment4ValueFormatted != undefined ? paymentFilter.payment4ValueFormatted.toLowerCase().match(filter) : ""
                    var payment5ValueFormatted = paymentFilter.payment5ValueFormatted != null && paymentFilter.payment5ValueFormatted != undefined ? paymentFilter.payment5ValueFormatted.toLowerCase().match(filter) : ""
                    var addendumValueFormatted = paymentFilter.addendumValueFormatted != null && paymentFilter.addendumValueFormatted != undefined ? paymentFilter.addendumValueFormatted.toLowerCase().match(filter) : ""
                    var balanceValueFormatted = paymentFilter.balanceValueFormatted != null && paymentFilter.balanceValueFormatted != undefined ? paymentFilter.balanceValueFormatted.toLowerCase().match(filter) : ""
                    var id = paymentFilter.id.toString().match(filter);

                    if(customerName != null) { return customerName; } 
                    else if(payment1ValueFormatted != null) { return payment1ValueFormatted; } 
                    else if(payment2ValueFormatted != null) { return payment2ValueFormatted; } 
                    else if(payment3ValueFormatted != null) { return payment3ValueFormatted; }
                    else if(payment4ValueFormatted != null) { return payment4ValueFormatted; }
                    else if(payment5ValueFormatted != null) { return payment5ValueFormatted; }
                    else if(addendumValueFormatted != null) { return addendumValueFormatted; }
                    else if(balanceValueFormatted != null) { return balanceValueFormatted; }
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (status, situation) {

                let color = "";

                switch (status) {

                    case 0:
                        color = 'var(--color__silver)';
                        break;

                    case 1:
                        color = 'var(--color__red)';
                        break;

                    case 2:
                        color = 'var(--color__main)';
                        break;

                    case 3:
                        color = 'var(--color__status_pending)';
                        break;

                    case 4:
                        color = 'var(--color__completed)';
                        break;

                    case 5:
                        color = 'var(--color__completed)';
                        break;

                    default:
                        color = 'var(--color__main)';
                        break;
                }

                switch (situation) {

                    case 2:
                    case 3:
                        color = 'var(--color__red)';
                        break;
                }

                return color
            },

            getValueColor(totalJob, totalPaid) {

                if (totalPaid < totalJob) {
                    return "color: var(--color__alert) !important;"
                }
                else {
                    return "color: var(--color__main) !important;"
                }
            },

            async getRegisters() {

                const requestFilter = {
                    idService: 0,
                    listPendingPayments: false
                }

                this.listPayments = await this.$store.dispatch("servicePaymentsModule/ListPayments", requestFilter);
                this.listPaymentsFiltered = [...this.listPayments];
                this.listCustomer = await this.$store.dispatch("customerModule/List");

                const listStatus = await this.$store.dispatch("serviceModule/ListStatus");
                this.listStatus = listStatus.filter(sts => sts.id >= 7).sort((a, b) => { return orderBy(a, b, 0, 'id') });

                this.filterRegisters();
            },

            async filterRegisters() {

                let idCustomer = 0;
                let idStatus = 0;
                let listFiltered = [...this.listPayments];

                if (this.filter.customerSelected != null && this.filter.customerSelected != undefined) {
                    idCustomer = this.filter.customerSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.idCustomer == idCustomer);
                }

                if (this.filter.statusSelected != null && this.filter.statusSelected != undefined) {
                    idStatus = this.filter.statusSelected.id;
                    listFiltered = listFiltered.filter(ser => ser.status == idStatus);
                }

                if (this.filter.showFullyPayed == null || this.filter.showFullyPayed == undefined || this.filter.showFullyPayed == 0) {
                    listFiltered = listFiltered.filter(ser => ser.totalPaid <= ser.totalJob);
                }

                this.listPaymentsFiltered = listFiltered;
            },

            async showDialogHold(type, id) {
                this.dialogHoldResume = {
                    id: id,
                    show: true,
                    type,
                    projectOrService: 'S',
                    methodConfirm: ( () => {  
                        this.getRegisters();
                    })
                }
            },

            async showDialogNotes(id) {
                this.dialogNotes = {
                    id: id,
                    show: true,
                    methodConfirm: ( () => {  
                        this.getRegisters();
                    })
                }
            },

            async showDialogPayments(id, idServicePayment, totalJob) {
                this.dialogPayments = {
                    id,
                    idServicePayment,
                    totalJob,
                    show: true,
                    methodConfirm: ( () => {  
                        this.getRegisters();
                    })
                }
            },

            async showDialogPaymentsDetails(id) {
                this.dialogPaymentsDetails = {
                    idAux: Math.random(),
                    idServicePayment: id,
                    show: true
                }
            },

            showPaymentDetails(_, item2) {
                item2.expand(true);
            }
        },

        async created() {
            this.showLoading();
            await this.getRegisters();
            this.hideLoading();
        }
    })
</script>

<style>

    table > tbody > tr {
        cursor: pointer !important;
    }

</style>